import { Box, Paragraph } from 'grommet';
import React, { FunctionComponent } from 'react'; 
import { useTranslation } from 'react-i18next';
import PexeEvent from '../../../../corelogic/entities/event.interface';

const Announcement: FunctionComponent< { viewportSize: string, event: PexeEvent }> = ({ viewportSize, event }) => {

  const { i18n } = useTranslation();

  return <Box pad={viewportSize !== 'small' ? 'small' : 'large'} flex align="center">
    <Paragraph responsive textAlign='center' color='darkBlue' size='xlarge'> 
      { i18n.language === "en" ? event.presentationTextEn : event.presentationTextFr }
    </Paragraph>
    <Paragraph responsive textAlign='center' color='darkBlue' size='large' margin='medium'> 
      { i18n.language === "en" ? event.subPresentationTextEn : event.subPresentationTextFr }
    </Paragraph>
    {/* <Text textAlign='center' color='darkBlue' margin='medium'> 
      { i18n.language === "en" ? event.presentationTextFr : event.presentationTextFr }
    </Text> */}
  </Box>
};

export default Announcement;
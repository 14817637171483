import { Box, Clock, Heading } from 'grommet';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ThematicHandler from '../../../../corelogic/usecases/thematic/thematicHandler';
import Thematic from './Thematic';
import {Thematic as ThematicEntity} from '../../../../corelogic/entities/thematic.interface';
import PexeSpinner from '../shared/PexeSpinner';
import PexeEventHandler from '../../../../corelogic/usecases/event/eventHandler';


type ThematicsDeps = {
  thematicHandler: ThematicHandler,
  eventHandler: PexeEventHandler
}
const Thematics: FunctionComponent<ThematicsDeps> = ({ thematicHandler, eventHandler }) => {

  const [thematics, setThematics] = useState<ThematicEntity[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const { t } = useTranslation();
 
  useEffect(() => {
    (async function getThematics() {

      const event = await eventHandler.getEventInformation();
      if(event?.glass) {
        const retrievedThematics = await thematicHandler.all();
        if(retrievedThematics) {
          setThematics(retrievedThematics);
          setLoading(false);
        }
      } else {
        history.push("/thematics/rating")
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(loading) return <PexeSpinner />

  return <Box
    as="nav"
    flex
    fill
    direction="column"
    align="center"
    justify="start"
    responsive
    style={{background: 'rgb(243, 243, 243)', minHeight: "600px"}}
    pad="large"
  >

    { thematics && thematics.length > 0 &&
      thematics.map((thematic) => <Thematic key={thematic.id} thematic={thematic} /> )
    }

    { thematics && thematics.length === 0 && <Box flex direction="column" margin={{vertical: "xlarge"}} justify="center" align="center" fill height="fill">
      <Box round='full' background='dark-3' pad='small'>
        <Clock size='large' />
      </Box>
      <Heading level="3" alignSelf="center" textAlign="center">
        { t('thematics.no_thematics_registered') }
      </Heading>
    </Box>}

  </Box>
};

export default Thematics;
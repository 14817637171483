import React, { FunctionComponent } from "react";
import { Menu } from 'grommet';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import datei18nFR from "../../../../utils/dateFormat.i18n.fr";
import datei18nEN from "../../../../utils/dateFormat.i18n.en";

const PexeLanguageSelector: FunctionComponent = () => {

  const { i18n } = useTranslation();

  const getLanguageDetails = () => {
    if(i18n.language === "fr") {
      return {
        img: <FrenchFlag />
      }
    }
    return {
      img: <EnglishFlag />
    }
  }

  const changeLanguage = (lng: "en" | "fr") => {
    i18n.changeLanguage(lng);
    dateFormat.i18n = lng === "en" ? datei18nEN : datei18nFR;
  }

  const getItems = () => {
    if(i18n.language === "fr") {
      return { gap: 'small', alignSelf: 'center', onClick: () => changeLanguage("en"), icon: <EnglishFlag />}
    }
    return { gap: 'small', alignSelf: 'center', onClick: () => changeLanguage("fr"), icon: <FrenchFlag />}
  }

  return <Menu
    style={{position: 'absolute', top: '15px', right: '30px'}}
    icon={ getLanguageDetails().img }
    items={[
      getItems()
    ]}
  />
};

const FrenchFlag: FunctionComponent = () => {
  return <img src={`${process.env.PUBLIC_URL}/FR.png`} alt="FR" style={{width: 45, maxHeight: 'auto'}} />
};

const EnglishFlag: FunctionComponent = () => {
  return <img src={`${process.env.PUBLIC_URL}/UK.png`} alt="EN" style={{width: 45, maxHeight: 'auto'}} />
};

export default PexeLanguageSelector;
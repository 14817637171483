import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom";
import PexeEventHandler from '../../../../corelogic/usecases/event/eventHandler';
import IdentityHandler from '../../../../corelogic/usecases/identity/identityHandler';
import RatingHandler from '../../../../corelogic/usecases/rating/ratingHandler';
import ThematicHandler from '../../../../corelogic/usecases/thematic/thematicHandler';


type GuardDeps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: FunctionComponent<any>,
    identityHandler?: IdentityHandler,
    eventHandler?: PexeEventHandler,
    ratingHandler?: RatingHandler,
    thematicHandler?: ThematicHandler
} & RouteProps;
const GuardedRoute: FunctionComponent<GuardDeps> = ({ component: Component, identityHandler, ...rest }) => {

  const { identified } = identityHandler?.currentJudgeStatus() ?? { identified: false };

  return <Route {...rest} render={(props) => (
    identified === true
      ? <Component {...props} />
      : <Redirect to="/" />
  )} />
}


export default GuardedRoute;

import { ThematicRates, Rate } from "../../entities/rate.interface";
import RatingGateway from "../../gateways/ratingGateway";

export default class RatingHandler {


  constructor(private source: RatingGateway) {}

  get ratedCriteria(): ThematicRates {
    return this.source.ratedCriteria;
  }

  async emitRates(ratedCriterion: Rate[], companyId: number, thematicId: string): Promise<ThematicRates> {
    return this.source.emitRates(ratedCriterion, companyId, thematicId);
  }
}
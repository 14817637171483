import { Box, Heading, Text } from 'grommet';
import React, { FunctionComponent } from 'react';
import dateFormat from 'dateformat';
import PexeEvent from '../../../../corelogic/entities/event.interface';
import IdentityHandler from '../../../../corelogic/usecases/identity/identityHandler';
import HomeContent from './HomeContent';
import PexeSession from './Session';
import datei18nFR from '../../../../utils/dateFormat.i18n.fr';
import PexeEventHandler from '../../../../corelogic/usecases/event/eventHandler';
import i18n from '../../../../i18n';

dateFormat.i18n = datei18nFR;

type HomeDeps = {
  identityHandler: IdentityHandler;
  eventHandler: PexeEventHandler;
  event: PexeEvent;
}
const HomeContainer: FunctionComponent<HomeDeps> = ({ identityHandler, eventHandler, event }) => {
  return <Home identityHandler={identityHandler} event={event} eventHandler={eventHandler} />
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Home: FunctionComponent<HomeDeps> = ({ identityHandler, eventHandler, event }) => {

  return <div style={{width: '100%', minHeight: '100%', background: '#f3f3f3', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>

    <PexeSession identityHandler={identityHandler} />

    <div style={{padding: '15px', minHeight:'100%'}}>
      <Box
        flex
        height="100%"
        direction='column'
        justify='center'
        pad={{ left: 'medium', right: 'small', top: 'medium', bottom: 'medium' }}
      >
        <Box direction='column'
          align='center'
          margin="medium"
          justify='center'>
          <Heading responsive level='1' textAlign='center' margin='none' color='darkBlue'>
            { event.eventName.toUpperCase() }
          </Heading>
        </Box>
        

        <Box  
          direction='column'
          align='center'
          margin="medium"
          justify='center' 
          color='darkBlue'
        > 
          <Text style={{fontWeight: 'bold'}} margin='small' size='large'> { 

            i18n.language === "fr" ? dateFormat(event.date, "dddd d mmmm yyyy") : dateFormat(event.date, "fullDate")

          } </Text>

        </Box>
        <HomeContent identityHandler={identityHandler} event={event} />
      </Box>

    </div>
      
  </div>
} 

export default HomeContainer;
import { Thematic } from "../../corelogic/entities/thematic.interface";

const thematicsFixtures: Thematic[] = [
  {
    id: 7,
    themeNameEn: 'Adapting to Climate Change: Water and Biodiversity',
    themesNameFr: 'Adaptation au changement climatique : Eau et biodiversité',
    event_id: 2
  },
  {
    id: 23,
    themeNameEn: 'Sustainable building and constructions',
    themesNameFr: 'Bâtiments et constructions durables',
    event_id: 2
  },
  {
    id: 27,
    themeNameEn: 'Waste and circular economy',
    themesNameFr: 'Déchets et économie circulaire',
    event_id: 2
  },
  {
    id: 70,
    themeNameEn: 'Energy: RE and energy efficiency',
    themesNameFr: 'Energie : EnR et efficacité énergétique',
    event_id: 2
  },
];

export default thematicsFixtures;
import { Box, ResponsiveContext } from 'grommet';
import React, { FunctionComponent } from 'react';
import PexeEvent from '../../../../corelogic/entities/event.interface';
import IdentityHandler from '../../../../corelogic/usecases/identity/identityHandler';
import Announcement from './Announcement';
import Login from './Login';

type HomeDeps = {
  identityHandler: IdentityHandler;
  event: PexeEvent;
}
const HomeContent: FunctionComponent<HomeDeps> = ({ identityHandler, event }) => {
  return <ResponsiveContext.Consumer>
    {
      size => <Box
        as='nav'
        flex
        direction={size !== 'small' ? 'row' : 'column'}
        align={size !== 'small' ? 'start' : 'center'}
        justify={size !== 'medium' ? 'start' : 'between'}
        responsive
        pad={{ left: 'medium', right: 'small' }}
      >
        <Announcement event={event} viewportSize={size} />
        <Login identityHandler={identityHandler} />
      </Box>
    }
  </ResponsiveContext.Consumer>
};

export default HomeContent;
import { ThematicRates, Rate } from "../../../../corelogic/entities/rate.interface";
import JudgeIdentityGateway from "../../../../corelogic/gateways/judgeIdentityGateway";
import RatingGateway from "../../../../corelogic/gateways/ratingGateway";

export default class InMemoryRatingGateway implements RatingGateway {

  ratedCriteria: ThematicRates = {};

  // eslint-disable-next-line no-useless-constructor
  constructor(private judgeIdentityHandler: JudgeIdentityGateway) {}

  async emitRates(rates: Rate[], companyId: number, thematicId: string): Promise<ThematicRates> {
   
    const { identified, current } = this.judgeIdentityHandler.currentJudgeStatus();
   
    if(!identified) return {};

    rates = rates.map(rate => {
      rate.judge_id = current.id;
      return rate;
    });

    if(!this.ratedCriteria[thematicId])
      this.ratedCriteria[thematicId] = {};

    this.ratedCriteria[thematicId][companyId] = {
      ...this.ratedCriteria[thematicId][companyId],
      rates
    }
  
    current.rates = {...this.ratedCriteria};

    this.judgeIdentityHandler.updateSession();

    return this.ratedCriteria;
  }
}
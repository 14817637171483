import thematicsFixtures from "./fixtures/thematics/thematicsFixtures";
import InMemoryThematicGateway from "./adapters/secondary/gateways/inmemory/inMemoryThematicGateway";
import ThematicGateway from "./corelogic/gateways/thematicGateway";
import InMemoryRatingGateway from "./adapters/secondary/gateways/inmemory/inMemoryRatingGateway";
import InMemoryJudgeIdentityGateway from "./adapters/secondary/gateways/inmemory/inMemoryJudgeIdentityGateway";
import RatingGateway from "./corelogic/gateways/ratingGateway";
import JudgeIdentityGateway from "./corelogic/gateways/judgeIdentityGateway";
import { EventGateway } from "./corelogic/gateways/eventGateway";
import InMemoryEventGateway from "./adapters/secondary/gateways/inmemory/inMemoryEventGateway";
import IdentityHandler from "./corelogic/usecases/identity/identityHandler";
import ThematicHandler from "./corelogic/usecases/thematic/thematicHandler";
import RatingHandler from "./corelogic/usecases/rating/ratingHandler";
import PexeEventHandler from "./corelogic/usecases/event/eventHandler";
import HttpThematicGateway from "./adapters/secondary/gateways/http/httpThematicGateway";
import HttpEventGateway from "./adapters/secondary/gateways/http/httpEventGateway";
import HttpRatingGateway from "./adapters/secondary/gateways/http/httpRatingGateway";
import HttpJudgeIdentityGateway from "./adapters/secondary/gateways/http/httpJudgeIdentityGateway";

type Dependencies = {
    thematicGateway: ThematicGateway;
    ratingGateway: RatingGateway;
    identityGateway: JudgeIdentityGateway;
    eventGateway: EventGateway;
}

export function bootstrapDependencies(): Dependencies {
  const env: string | undefined = process.env.REACT_APP_MODE ?? 'INMEMORY';


  if(env === 'INMEMORY') {
    const identityGateway = new InMemoryJudgeIdentityGateway();

    return {
      thematicGateway: new InMemoryThematicGateway(thematicsFixtures),
      ratingGateway: new InMemoryRatingGateway(identityGateway),
      identityGateway,
      eventGateway: new InMemoryEventGateway()
    }
  }

  const identityGateway = new HttpJudgeIdentityGateway();

  return {
    thematicGateway: new HttpThematicGateway(),
    ratingGateway: new HttpRatingGateway(identityGateway),
    identityGateway,
    eventGateway: new HttpEventGateway()
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function initHandlers() {
  const { thematicGateway, identityGateway, ratingGateway, eventGateway } = bootstrapDependencies();

  const identityHandler = new IdentityHandler(identityGateway);
  const thematicHandler = new ThematicHandler(thematicGateway);
  const ratingHandler = new RatingHandler(ratingGateway);
  const eventHandler = new PexeEventHandler(eventGateway);

  return { identityHandler, thematicHandler, ratingHandler, eventHandler };
}

import { Company } from "../../corelogic/entities/company.interface";
import thematicsFixtures from "./thematicsFixtures";

const [ theme1, theme2 ] = thematicsFixtures;


const companiesTheme1: Company[] = [
  {
    id: 5,
    companyName: "Thales",
    textFr: "Thales présentation 1",
    textEn: "Thales presentation 1",
  },
  {
    id: 15,
    companyName: "Safran",
    textFr: "Safran présentation 1",
    textEn: "Safran presentation 1",
  }
];

const companiesTheme2: Company[] = [
  {
    id: 123,
    companyName: "Accenture",
    textFr: "Accenture présentation 2",
    textEn: "Accenture presentation 2",
  },
  {
    id: 152,
    companyName: "Dassault",
    textFr: "Dassault présentation 2",
    textEn: "Dassault presentation 2",
  }
];

const companiesByThematics: { [theme: string]: Company[] }  = {
  [theme1.id]: companiesTheme1,
  [theme2.id]: companiesTheme2
};

export default companiesByThematics ;
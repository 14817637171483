/* eslint-disable no-nested-ternary */
import { Box, Image } from 'grommet';
import React, { FunctionComponent, useEffect, useState } from 'react';
import PexeEvent from '../../../../corelogic/entities/event.interface';
import PexeEventHandler from '../../../../corelogic/usecases/event/eventHandler';
import PexeLanguageSelector from './PexeLanguageSelector';

const PexeHeader: FunctionComponent<{eventHandler: PexeEventHandler, event: PexeEvent}> = ({ eventHandler, event }): JSX.Element => {  
  const [header, setHeader] = useState<string>();


  useEffect(() => {

    let mounted = true;

    const fetchFooter = () => {
      if(event) {
        eventHandler.getEventHeader()
          .then(retrievedHeader => {
            if(retrievedHeader) setHeader(retrievedHeader)
          });
      }
    }
    if(mounted) {
      fetchFooter();
    }

    return () => {
      mounted = false;
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return <Box
    as='header'
    direction='row'
    align='center'
    justify='center'
  >

    {
      header ?
        <Image 
          src={header} 
          fit="cover"
          alt="Pexe Header" 
          style={{maxHeight: "500px", overflow: "hidden", width: "100%", height: "auto"}}
        />
        : <div style={{height: "500px", padding: "12px"}} />
    }
    
    { event && event.en_available && <PexeLanguageSelector /> }
  </Box>
};

export default PexeHeader;

import PexeEvent from "../../entities/event.interface";
import { EventGateway } from "../../gateways/eventGateway";

export default class PexeEventHandler {
  constructor(private source: EventGateway) {}

  async getEventInformation(): Promise<PexeEvent | null> {
    return this.source.getEventInformation();
  }

  async getEventFooter(): Promise<string | null> {
    if(this.source.getEventFooter) {
      return this.source.getEventFooter();
    }
    return null;
  }

  async getEventHeader(): Promise<string | null> {
    if(this.source.getEventHeader) {
      return this.source.getEventHeader();
    }
    return null;
  }

  async getEntitiesInformation(thematicId?: number): Promise<any> {
    return this.source.obtainEntitiesInformation(thematicId);
  }
}
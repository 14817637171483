import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import { Card, CardBody } from 'grommet';
import { useTranslation } from "react-i18next";
import { Thematic as ThematicInterface } from "../../../../corelogic/entities/thematic.interface";

const Thematic: FunctionComponent<{thematic: ThematicInterface }>= ({ thematic }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return <Card 
    width="70%" background="darkBlue" 
    elevation="medium"
    onClick={() => history.push(`/thematics/${thematic.id}`)} 
    margin={{top: 'medium', bottom: 'medium', horizontal: 'auto'}}>
    <CardBody align="center" pad="medium">
      
      {
        i18n.language === "en" ? thematic.themeNameEn : thematic.themesNameFr
      }

    </CardBody>
  </Card>
};

export default Thematic;
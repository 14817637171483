/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { Company } from "../../../../corelogic/entities/company.interface";
import { Criterion } from "../../../../corelogic/entities/criterion.interface";
import { Thematic } from "../../../../corelogic/entities/thematic.interface";
import ThematicGateway from "../../../../corelogic/gateways/thematicGateway";

export default class HttpThematicGateway implements ThematicGateway {
    
    endpoint = process.env.REACT_APP_API_URL;

    async all(): Promise<Thematic[]> {
      try {
        const { data } = await axios.get(`${this.endpoint}/themes`);
        return data;
      } catch(error) {
        return [];
      }
    }

    async allCompanies(): Promise<Company[]> {
      try {
        const { data } = await axios.get(`${this.endpoint}/companies`);
        return data;
      } catch(error) {
        return [];
      } 
    }

    async allCriteria(): Promise<Criterion[]> {
      try {
        const { data } = await axios.get(`${this.endpoint}/criterias`);
        return data;
      } catch(error) {
        return [];
      } 
    }

    async allCompaniesByThematic(themeId: number): Promise<Company[]> {
      try {
        const { data } = await axios.get(`${this.endpoint}/companies/${themeId}`);
        return data;
      } catch(error) {
        return [];
      } 
    }

    async allCriteriaByThematic(themeId: number): Promise<Criterion[]> {
      try {
        const { data } = await axios.get(`${this.endpoint}/criterias/${themeId}`);
        return data;
      } catch(error) {
        return [];
      } 
    }
    
}
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { ThematicRates, Rate } from "../../../../corelogic/entities/rate.interface";
import JudgeIdentityGateway from "../../../../corelogic/gateways/judgeIdentityGateway";
import RatingGateway from "../../../../corelogic/gateways/ratingGateway";

export default class HttpRatingGateway implements RatingGateway {
    
    endpoint = process.env.REACT_APP_API_URL;

    ratedCriteria: ThematicRates = {};

    // eslint-disable-next-line no-useless-constructor
    constructor(private judgeIdentityHandler: JudgeIdentityGateway) {}

    async emitRates(rates: Rate[], companyId: number, thematicId: string): Promise<ThematicRates> {
      try {

        const { identified, current } = await this.judgeIdentityHandler.currentJudgeStatus();


        if(!identified) return {};

        await Promise.all(
          rates.map(rate => axios.post<ThematicRates>(`${this.endpoint}/rates/${companyId}/${rate.criteria_id}/${current.id}`,  { rate: rate.rate } ))
        );
        
        if(!this.ratedCriteria[thematicId])
          this.ratedCriteria[thematicId] = {};

        this.ratedCriteria[thematicId][companyId] = {
          ...this.ratedCriteria[thematicId][companyId],
          rates
        }
        
        current.rates = {...this.ratedCriteria};

        await this.judgeIdentityHandler.updateSession();
    
        return this.ratedCriteria;
      } catch(error) {
        return this.ratedCriteria;
      }
    }
    
}
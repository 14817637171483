import { useHistory } from "react-router-dom";
import { Box, Form, TextInput, Button, Heading, ResponsiveContext } from 'grommet';
import { Mail, Login as LoginIcon, Clipboard } from 'grommet-icons';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import IdentityHandler from "../../../../corelogic/usecases/identity/identityHandler";

interface Login {
    entity: string;
    email: string;
}

type LoginDeps = {
  identityHandler: IdentityHandler;
}
const Login: FunctionComponent<LoginDeps> = ({ identityHandler }) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSubmit, register, errors, formState } = useForm({mode: 'all'});
  const { t } = useTranslation();
  const history = useHistory();
  const size = useContext(ResponsiveContext);
  const [loginError, setLoginError] = useState(null);

  const navigateToThematics = () => history.push('/thematics');
  const { isDirty, isValid } = formState;

  useEffect(() => {
    if(identityHandler.currentJudgeStatus().identified) {
      navigateToThematics();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidLogin = async ({ entity, email }: Login) => {
    try {
      await identityHandler.register({ entityName: entity, email, id: 0, rates: {} });
      navigateToThematics();    
    } catch {
      setLoginError(t('homepage.login_error'));
    }
  }

  return (
    <div>
      <Form
        style={{ margin: size === "large" ? "35px 35px 35px 150px" : "35px"}} 
        onSubmit={handleSubmit(handleValidLogin)}

      >

        <TextInput 
          id="entity-id" name="entity" 
          size="large"
          placeholder={ t('homepage.entity') }
          icon={<Clipboard style={{ paddingLeft: '6px'}} color='darkBlue'/>}
          style={{margin: '10px'}}
          ref={register({
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9_ ]*$/,
              message: t('homepage.invalid_entity')
            }
          })}
        />

        <Heading style={{margin: '10px'}} level="6" color="status-critical"> 
          {errors.entity && errors.entity.message}
        </Heading>


        <TextInput 
          id="email-id" name="email" 
          size="large"
          placeholder={ t('homepage.mail') }
          icon={<Mail style={{ paddingLeft: '6px'}} color='darkBlue' />}
          style={{margin: '10px'}}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t('homepage.invalid_mail')
            }
          })}
        />

        <Heading style={{margin: '10px'}} level="6" color="status-critical"> 
          {errors.email && errors.email.message}
        </Heading>


        
        <Heading style={{margin: '10px'}} level="6" color="status-critical"> 
          {loginError && loginError}  
        </Heading>
        
        

        <Box direction="row" flex justify='center' pad='medium' margin={{top: "15px"}}>
          <Button size="large" disabled={!isDirty || !isValid} icon={<LoginIcon />} color='lightBlue' type="submit" primary label={ t('homepage.login') } />
        </Box>
      </Form>

      
    </div>
  );
};

export default Login;
import { Box, Button, Heading, Layer, ResponsiveContext } from 'grommet';
import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmRating: FunctionComponent<{showModal: boolean, confirm: () => void, dismiss: () => void}> = ({showModal, confirm, dismiss}) => {

  const { t } = useTranslation();

  const size = useContext(ResponsiveContext);


  if(showModal) {
    return <Layer
      onEsc={() => {dismiss()}}
      onClickOutside={() => dismiss}
    >
      <Box pad="large" flex direction="column">
        <Heading level="4" margin={{bottom: 'large'}}>
          { t('rating.modal_confirm_rating') }
        </Heading>

        <Box flex direction="row" justify={size === "small" ? "around" : "between"} align="center" margin={{top: 'medium'}}>
          <Button color="status-ok" onClick={() => confirm()}>
            { t('rating.confirm_rating') }
          </Button>

          <Button color="status-warning" onClick={() => dismiss()}>
            { t('rating.dismiss_rating') }
          </Button>
        </Box>
      </Box>
    </Layer>
  }

  return null;

}

export default ConfirmRating;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Form, Heading, ResponsiveContext, Text } from "grommet";
import { Alert, Contact, Save, StatusGood, Workshop, Close } from "grommet-icons";
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import RatingCriteria from "./RatingCriteria";
import { Criterion } from "../../../../corelogic/entities/criterion.interface";
import { Company } from "../../../../corelogic/entities/company.interface";
import RatingHandler from "../../../../corelogic/usecases/rating/ratingHandler";
import { Rate } from "../../../../corelogic/entities/rate.interface";
import { Judge, JudgeIdentity } from "../../../../corelogic/entities/judge.interface";
import IdentityHandler from "../../../../corelogic/usecases/identity/identityHandler";

type EntityRatingDeps = {
  criteria: Criterion[];
  company: Company;
  ratingHandler: RatingHandler;
  thematicId: string;
  identityHandler: IdentityHandler;

  submit: boolean;
};
const EntityRating: FunctionComponent<EntityRatingDeps> = ({ criteria, company, ratingHandler, thematicId, identityHandler, submit }) => {

  const { t, i18n } = useTranslation();
  const size = useContext(ResponsiveContext);
  const { handleSubmit, register, errors, reset } = useForm({ mode: 'all' });

  const [judge] = useState<Judge>(identityHandler.currentJudgeStatus().current);

  const [isRated, setRated] = useState<boolean>(thematicId in judge.rates && company.id in judge.rates[thematicId]);
  const [isRating, setIsRating] = useState<boolean>(false);
  const [isSubscribe, setSubscription] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<{ [id: string]: string }>({});
  // eslint-disable-next-line @typescript-eslint/no-empty-function

  const handleValidRating = useCallback(async () => {
    setIsRating(true);
    const ratingsByCriteria = formValue;
    const formattedRates: Rate[] = [];
    Object.keys(ratingsByCriteria).forEach((criteriaId) => {
      const rateValue = parseInt(ratingsByCriteria[criteriaId], 10);

      const rate: Rate = {
        rate: rateValue,
        judge_id: judge.id,
        companies_id: company.id,
        criteria_id: parseInt(criteriaId, 10)
      }
      formattedRates.push(rate);
    });

    try {
      await ratingHandler.emitRates(formattedRates, company.id, thematicId);

      if (judge.rates[thematicId][company.id]) {
        setRated(true);
      }
      reset();

      // eslint-disable-next-line no-empty
    } catch { }

    setIsRating(false);
  }
  , [company.id, formValue, judge.id, judge.rates, ratingHandler, reset, thematicId]);

  useEffect(() => {

    if (submit && !isRated && !isRating && criteria.length !== 0 && criteria.length === Object.keys(formValue).length) handleValidRating()
  }, [submit, isRated, formValue, judge.id, judge.rates, company.id, ratingHandler, thematicId, reset, isRating, criteria.length, handleValidRating])

  const subscribeToEntity = async () => {
    setIsRating(true);
    try {
      await identityHandler.subscribeToEntity(judge.id, company.id);
      setSubscription(true);
      // eslint-disable-next-line no-empty
    } catch {
      setSubscription(false);
    }
    setIsRating(false);
  }

  return <Form
    value={formValue}
    onChange={value => setFormValue(value)}
  >
    <Box
      background='white'
      flex
      direction={size !== "large" ? "column" : "row"}
      style={{ width: size === "large" ? "100%" : "95%", position: 'relative' }}
      justify="between"
      pad="medium"
      margin={{ vertical: "medium", horizontal: "auto" }}
      elevation="medium">
      <Box style={{ width: size !== "large" ? "100%" : "30%" }}>
        <Box flex direction='row' responsive align="center" justify="between" pad="medium" margin={{ top: '25px' }}>
          <Heading level="2" textAlign={size === "small" ? "center" : "start"}>
            {company.companyName}
          </Heading>
        </Box>
        <Box flex direction="row" responsive align="center" justify={size === "small" ? "center" : "start"}>
          <Text alignSelf="start" wordBreak="keep-all" size="medium" margin="xsmall">{i18n.language === "en" ? company.textEn : company.textFr} </Text>
        </Box>
      </Box>
      {
        !isRated ?
          <Box width="large" margin="0 auto">
            <Text style={{ display: 'flex' }} textAlign="center" alignSelf="center" margin={{ vertical: "small" }}>
              {errors.rate && <Alert color="status-error" style={{ marginRight: '5px' }} />}
              {errors.rate && t('rating.form.error')}
            </Text>

            {criteria && criteria.length > 0 ?
              <Box style={{ maxHeight: '400px', overflowY: "auto" }} flex direction="column" justify="center">
                {criteria.map(criterion => <RatingCriteria key={criterion.id} criterion={criterion} register={register} />)}
              </Box> : <Heading level="3" textAlign="center" alignSelf="center">
                {t('rating.no_criteria_found')}
              </Heading>
            }

          </Box> : <StatusGood style={{ display: "flex", alignSelf: "center" }} size="large" color="pexeGreen" />
      }

      <Box alignSelf="center" margin={{ left: size === 'large' ? '50px' : '0px' }}>
        {
          !isRated ? (
            <Button
              label={t('rating.save')}
              disabled={isRating || criteria.length === 0 || criteria.length !== Object.keys(formValue).length}
              alignSelf="center"
              icon={<Save />}
              margin="medium"
              type="button"
              color="lightBlue"
              onClick={handleValidRating}
              focusIndicator={false} />
          ) : (
            <Button
              label={t('rating.back_to_notation')}
              alignSelf="center"
              icon={<Close/>}
              margin="medium"
              type="button"
              color="red"
              onClick={() =>  {setRated(false)}}
              focusIndicator={false} />
          )
        }

        <Button
          disabled={isRating}
          onClick={() => subscribeToEntity()}
          icon={<Contact />}
          margin="medium"
          label={isSubscribe ? t('rating.contact_request_success') : t('rating.contact_request')}
          color="darkBlue" />
      </Box>
    </Box>
  </Form>
};

export default EntityRating;

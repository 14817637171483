/* eslint-disable @typescript-eslint/no-unused-vars */
import PexeEvent from "../../../../corelogic/entities/event.interface";
import { EventGateway } from "../../../../corelogic/gateways/eventGateway";
import { currentEventFixture } from "../../../../fixtures/event/event.fixture";

export default class InMemoryEventGateway implements EventGateway {
  async getEventInformation(): Promise<PexeEvent | null> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(currentEventFixture);
      }, 1000);
    });
  }

  async obtainEntitiesInformation(thematicId?: number): Promise<void> {
    console.log(` contact information requested on ${thematicId}`);
  }
  
}
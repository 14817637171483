import axios from "axios";
import PexeEvent from "../../../../corelogic/entities/event.interface";
import { EventGateway } from "../../../../corelogic/gateways/eventGateway";

export default class HttpEventGateway implements EventGateway {
    endpoint = process.env.REACT_APP_API_URL;

    eventInformation: PexeEvent | null = null;

    async getEventInformation(): Promise<PexeEvent | null> {

      if(this.eventInformation) return this.eventInformation;

      try {
        const { data } = await axios.get(`${this.endpoint}/events`);
        this.eventInformation = data;
        return data;
      } catch {
        return this.eventInformation;
      }
    }

    async getEventFooter(): Promise<string | null> {
      try {
        const { data } = await axios.get(`${this.endpoint}/images/footer`);
        return data;
      } catch {
        return null;
      }
    }

    async getEventHeader(): Promise<string | null> {
      try {
        const { data } = await axios.get(`${this.endpoint}/images/header`);
        return data;
      } catch {
        return null;
      }
    }

    async obtainEntitiesInformation(thematicId?: number): Promise<any> {
      try {

        let customEndpoint;

        if(thematicId) {
          customEndpoint = `${this.endpoint}/export/contact/${thematicId}`;
        } else {
          customEndpoint = `${this.endpoint}/export/contact`;
        }

        const { data } = await axios.get(`${customEndpoint}`,
          { 
            responseType: 'blob',
          }
        )
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `contact-information.pdf`);
        document.body.appendChild(link);
        link.click();
      
      // eslint-disable-next-line no-empty
      } catch {}
    }
    
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grommet, Heading } from 'grommet';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './i18n';

import './styles/index.css';
import { useTranslation } from 'react-i18next';
import Theme from './theme';
import HomeContainer from './adapters/primary/components/homepage/Home';
import PexeHeader from './adapters/primary/components/shared/PexeHeader';
import PexeFooter from './adapters/primary/components/shared/PexeFooter';
import Rate from './adapters/primary/components/rating/Rate';
import bootstrapHandlers from './bootstrapDependencies';
import GuardedRoute from './adapters/primary/components/shared/GuardedRoute';
import Thematics from './adapters/primary/components/thematics/Thematics';
import PexeEvent from './corelogic/entities/event.interface';
import PexeSpinner from './adapters/primary/components/shared/PexeSpinner';
import { currentEventFixture } from './fixtures/event/event.fixture';

const { identityHandler, thematicHandler, ratingHandler, eventHandler } = bootstrapHandlers();


function App(): JSX.Element {

  const { t } = useTranslation();
  const [event, setEvent] = useState<PexeEvent | null>();
  const [loadingEvent, setLoadingEvent] = useState<boolean>(false);

  useEffect(() => {
    (async function getEvent() {
      setLoadingEvent(true);
      const currentEvent = await eventHandler.getEventInformation();
      if(currentEvent) {
        setEvent(currentEvent)
      }
      setLoadingEvent(false);
    })();
  }, []);

  if(loadingEvent) return <Grommet theme={Theme} className="root-container">
    <PexeSpinner />
  </Grommet>


  if(!event || !event.finished) return <Box flex margin={{top: "20px"}} align="center" justify="center" direction="column" fill height="fill">
    <img src={`${process.env.PUBLIC_URL}/pexe-logo.jpg`} alt="pexe-logo" style={{width: '200px', height: 'auto'}} />
    <Heading textAlign="center" level="3" responsive> { t('event.no_scheduled_event') }</Heading>
  </Box>

  return (
    <Router>
      <Grommet theme={Theme} className="root-container">
        <PexeHeader eventHandler={eventHandler} event={event} />
        <Switch>
          <GuardedRoute 
            path="/thematics/:id" 
            component={RatingContainer} 
            identityHandler={identityHandler} 
          />          
          <GuardedRoute path="/thematics" 
            component={ThematicContainer} 
            identityHandler={identityHandler} 
          />          
          <Route path="/">
            <HomeContainer identityHandler={identityHandler} event={event} eventHandler={eventHandler} />
          </Route>
        </Switch>
        <PexeFooter eventHandler={eventHandler} />
      </Grommet>
    </Router>
  );
}


const ThematicContainer: FunctionComponent = () => {
  return <Thematics 
    thematicHandler={thematicHandler} 
    eventHandler={eventHandler} 
  />
};

const RatingContainer: FunctionComponent = () => {
  return <Rate 
    ratingHandler={ratingHandler} 
    eventHandler={eventHandler}
    thematicHandler={thematicHandler} 
    identityHandler={identityHandler} />
};

export default App;

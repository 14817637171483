

import { Box, Text, Select } from "grommet";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Criterion } from "../../../../corelogic/entities/criterion.interface";


type RatingCriteriaProps = {
  criterion: Criterion;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}

const RatingCriteria: FunctionComponent<RatingCriteriaProps> = ({ criterion, register }) => {
  const [rate, setRate] = useState()
  const { t, i18n } = useTranslation();
  useEffect(() => {
    register(criterion.id.toString(), {
      required: true,
      min: 1,
      max: 10,
      pattern: {
        value: /^([1-9]|10)$/,
        message: t('rating.form.error')
      }
    })
  }, [criterion.id, register, t])

  return <Box
    style={{ minHeight: 45 }}
    flex
    direction="row"
    justify="between"
    align="center"
    margin="small">

    <Text textAlign="start" wordBreak="break-all" style={{ width: "35%" }}>
      {i18n.language === "en" ?
        (criterion.criteriaEn ?? t('rating.criterion')) :
        (criterion.criteriaFr ?? t('rating.criterion'))
      }
    </Text>

    <Box margin="medium" alignContent="end">
      <Select
        name={criterion.id.toString()}
        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        value={rate}
        onChange={({ option }) => {
          setRate(option)
        }}
        placeholder={t('rating.label')} />
      {/*
      <TextInput
        name={criterion.id.toString()}
        ref={register({
          required: true,
          min: 1,
          max: 10,
          pattern: {
            value: /^([1-9]|10)$/,
            message: t('rating.form.error')
          }
        })}
        min="1"
        max="10"
        // eslint-disable-next-line no-nested-ternary
        style={{ width: size === "small" ? "80px" : size === "medium" ? "250px" : "325px" }}
        placeholder={size !== "small" ? t('rating.interval') : t('rating.label')} type="number" textAlign="center" />
        */}
    </Box>

  </Box>
}

export default RatingCriteria;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Anchor, Box, Clock, Heading, Text, Button, ResponsiveContext } from "grommet";
import { ContactInfo, Download, FormNext, FormPreviousLink, Save } from "grommet-icons";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RatingHandler from "../../../../corelogic/usecases/rating/ratingHandler";
import EntityRating from "./EntityRating";
import { Company } from "../../../../corelogic/entities/company.interface";
import ThematicHandler from "../../../../corelogic/usecases/thematic/thematicHandler";
import { Criterion } from "../../../../corelogic/entities/criterion.interface";
import IdentityHandler from "../../../../corelogic/usecases/identity/identityHandler";
import PexeSpinner from "../shared/PexeSpinner";
import PexeEventHandler from "../../../../corelogic/usecases/event/eventHandler";
import ConfirmRating from "./ConfirmRating";

type RateDeps = {
  ratingHandler: RatingHandler;
  thematicHandler: ThematicHandler;
  identityHandler: IdentityHandler;
  eventHandler: PexeEventHandler;
}
const Rate: FunctionComponent<RateDeps> = ({ ratingHandler, thematicHandler, identityHandler, eventHandler }) => {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams();

  const size = useContext(ResponsiveContext);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [criteria, setCriteria] = useState<Criterion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingContactInformation, setLoadingContactInformation] = useState<boolean>(false);
  const [glass, setGlass] = useState<boolean>();
  const [submitForms, setSubmitForms] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);


  useEffect(() => {
    if (submitForms)
      setSubmitForms(false);
  }, [submitForms]);

  const getCriterionLibelle = (criterion: Criterion, index: number) => {
    if (i18n.language === "en") {
      const criterionLibelleEN = criterion.criteriaEn ?? getBlankCriterion(index);
      return `${criterionLibelleEN} : ${criterion.weight}%`;
    }

    const criterionLibelleFR = criterion.criteriaFr ?? getBlankCriterion(index);
    return `${criterionLibelleFR} : ${criterion.weight}%`;
  };

  const getBlankCriterion = (index: number) => `${t("rating.criterion")} ${index + 1}`;

  const getEntitiesInformation = async () => {
    setLoadingContactInformation(true);
    const param = parseInt(params.id, 10);
    const thematicId = Number.isInteger(param) ? params.id : null;

    const event = await eventHandler.getEventInformation();
    if (event) {
      await eventHandler.getEntitiesInformation(thematicId);
    }
    setLoadingContactInformation(false);
  };

  useEffect(() => {
    (async function getCompaniesByThematic() {

      try {

        const param = parseInt(params.id, 10);

        setGlass(Number.isInteger(param));

        const criteriaByTheme = await thematicHandler.allCriteria(Number.isInteger(param) ? params.id : null);

        if (criteriaByTheme && criteriaByTheme.length > 0) {
          setCriteria(criteriaByTheme);
        }

        const retrievedCompanies = await thematicHandler.allCompanies(Number.isInteger(param) ? params.id : null);
        if (retrievedCompanies && retrievedCompanies.length > 0) {
          setCompanies(retrievedCompanies);
        }

        setLoading(false);

      } catch {
        setLoading(false);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <PexeSpinner />;

  return <Box
    flex
    style={{ flex: 1, minHeight: "600px", background: "rgb(243, 243, 243)" }}
    pad="large"
    direction="column">

    <Box flex style={{ flex: 0.05 }} direction="row" align="center" justify="start">
      {
        glass && <Anchor onClick={() => history.push("/thematics")} label={t("thematics.label")} color="darkBlue" />
      }
      <FormNext />
      <Anchor label={t("rating.label")} color="darkBlue" />
    </Box>


    {
      criteria && criteria.length > 0 ?
        <Box flex style={{ flex: 0.3 }}>
          <Text margin="small"> {t("rating.criteria")} <br />
            {criteria.map((criterion, index) => {
              return `${getCriterionLibelle(criterion, index)} ${index !== criteria.length - 1 ? "|" : ""} `;
            })
            }
          </Text>

          <Text margin="small">
            {t("rating.interval")}
          </Text>

          <Text margin="small">
            {t("rating.advice")}
          </Text>

        </Box> : <Text> {t("rating.no_criteria_found")} </Text>
    }


    {
      companies && companies.length > 0 &&
      <Box flex direction="row" justify={size === "small" ? "center" : "end"} align="center"
        pad={size === "small" ? "medium" : ""}>
        <Box flex direction="column" align="end" pad={size === "small" ? "medium" : ""}>
          <Button
            disabled={loadingContactInformation}
            icon={<FormPreviousLink/>}
            label={t("rating.go_back")}
            color="black"
            onClick={() => history.push("/thematics")}
            margin={{bottom: "medium"}}
          />
          <Button
            disabled={loadingContactInformation}
            icon={<ContactInfo />}
            label={t("rating.obtain_entity_details")}
            color="pexeGreen"
            onClick={() => getEntitiesInformation()}
          />
        </Box>
      </Box>
    }

    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      companies && companies.length > 0 ?
        companies.map(company => {
          return <EntityRating key={company.id} criteria={criteria}
            identityHandler={identityHandler}
            thematicId={Number.isInteger(parseInt(params.id, 10)) ? params.id : "global"}
            company={company} ratingHandler={ratingHandler}
            submit={submitForms}
          />;
        }
        )
        : <Box flex direction="column" justify="center" align="center" fill height="fill" pad="medium">
          <Box round="full" background="dark-3" pad="small">
            <Clock size="large" />
          </Box>
          <Heading level="3" alignSelf="center" textAlign="center">
            {t("rating.no_companies_registered")}
          </Heading>
        </Box>
    }

    <ConfirmRating showModal={showModal} confirm={() => {setShowModal(false); setSubmitForms(true)}} dismiss={ () => setShowModal(false)}/>
    <Button
      label={t('rating.save')}
      // disabled={isRated || !formState.isValid || isRating || criteria.length === 0}
      alignSelf="center"
      icon={<Save />}
      margin="medium"
      type="button"
      onClick={() => setShowModal(true)}
      color="lightBlue"
      focusIndicator={false} />

  </Box>;
};


export default Rate;

import { Box, Button, Heading, Layer, ResponsiveContext } from 'grommet';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import IdentityHandler from "../../../../corelogic/usecases/identity/identityHandler";

const PexeSession: FunctionComponent<{ identityHandler: IdentityHandler }> = ({ identityHandler }) => {

  const history = useHistory();
  const { t } = useTranslation();

  const hasSession = () => {
    const pexeSession = localStorage.getItem("pexe-session");
    if(pexeSession) {
      const { expireAt } = JSON.parse(pexeSession);

      if(expireAt > new Date().getTime()) {
        return true;
      } 
      
      localStorage.removeItem("pexe-session");
    }
    return false;
  };

  const [showModal, setShowModal] = useState(hasSession());
  const size = useContext(ResponsiveContext);


  const retrieveSession = () => {
    setShowModal(false);
    identityHandler.retrieveSession();
    history.push('/thematics');
  }
  
  const dismissSession = () => {
    setShowModal(false);
    localStorage.removeItem("pexe-session");
  }

  if(showModal) {
    return <Layer
      onEsc={() => {setShowModal(true)}}
      onClickOutside={() => setShowModal(true)}
    >
      <Box pad="large" flex direction="column">
        <Heading level="4" margin={{bottom: 'large'}}>
          { t('homepage.session_found') }
        </Heading>

        <Box flex direction="row" justify={size === "small" ? "around" : "between"} align="center" margin={{top: 'medium'}}>
          <Button color="status-ok" onClick={() => retrieveSession()}>
            { t('homepage.session_retrieve') }
          </Button>

          <Button color="status-warning" onClick={() => dismissSession()}>
            { t('homepage.session_dismiss') }
          </Button>
        </Box>
      </Box>
    </Layer>
  }

  return null;

}

export default PexeSession;
const theme = {
  button: {
    border: {
      radius: '7px'
    },
    padding: {
      horizontal: '8px',
      vertical: '8px'
    }
  },
  global: {
    colors: {
      darkBlue: '#015986bf',
      lightBlue: '#178ff9',
      pexeGreen: 'rgb(106 154 19)',
      lightDark: 'rgb(71 71 71 / 93%)'
    },
    focus: {
      border: {
        color: '#0d5fa724'
      }
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

export default theme;
import { Company } from "../../entities/company.interface";
import { Criterion } from "../../entities/criterion.interface";
import { Thematic } from "../../entities/thematic.interface";
import ThematicGateway from "../../gateways/thematicGateway";

export default class ThematicHandler {

  constructor(private source: ThematicGateway) {}

  public async all(): Promise<Thematic[]> {
    return this.source.all();
  }

  public async allCompanies(themeId: number): Promise<Company[]> {
    if(themeId) {
      return this.source.allCompaniesByThematic(themeId);
    }
    return this.source.allCompanies();
  }

  public async allCriteria(themeId: number): Promise<Criterion[]> {
    if(themeId) {
      return this.source.allCriteriaByThematic(themeId);
    }
    return this.source.allCriteria();
  }

}
import PexeEvent from "../../corelogic/entities/event.interface";

const currentEventFixture: PexeEvent = {
  id: 12,
  eventName: "Pollutec innovation awards 2020",
  presentationTextEn: "Dear members of the jury, welcome to the rating app",
  presentationTextFr: "Chers membres du jury, bienvenue sur la plateforme de notation",
  en_available: true,
  current: true,
  finished: true,
  date: "2021-03-11T22:33:12.362Z",
  glass: true
};

export {
  // eslint-disable-next-line import/prefer-default-export
  currentEventFixture
}
import { Judge, JudgeIdentity } from "../../../../corelogic/entities/judge.interface";
import JudgeIdentityGateway from "../../../../corelogic/gateways/judgeIdentityGateway";

export default class InMemoryJudgeIdentityGateway implements JudgeIdentityGateway {
  
    currentJudge: JudgeIdentity = this.initSession();
      
    async register(judge: Judge): Promise<void> {

      const id = Math.random() * 1000; 

      this.currentJudge = {
        current: judge.id !== 0 ? judge : { ...judge, id },
        identified: true
      };

      const session = {
        profile: this.current(),
        expireAt: new Date().getTime() + 36e+6
      };

      localStorage.setItem("pexe-session", JSON.stringify(session));
    }
  
    currentJudgeStatus(): JudgeIdentity {
      return this.currentJudge;
    }

    current(): Judge {
      return this.currentJudge.current;
    } 

    retrieveSession(): { current: Judge, identified: boolean } | null {
      const session = localStorage.getItem("pexe-session");

      if(session) {
        const { profile, expireAt } = JSON.parse(session);

        if(expireAt > new Date().getTime()) {
          this.currentJudge = { current: profile, identified: true };
          return this.currentJudge;
        } 

        localStorage.removeItem("pexe-session");

        return null;
      }
      
      return null;
    }

    async updateSession(): Promise<void> {
      await this.register(this.current());
    }

    async subscribeToEntity(judgeId: number, entityId: number): Promise<void> {
      console.log(` ${judgeId} subscribe to ${entityId}`);
    }

    private initSession() {
      return {
        current: {
          id: 0,
          entityName: "",
          email: "",
          rates: {},
        },
        identified: false
      };
    }

    
}
import { Judge, JudgeIdentity } from "../../entities/judge.interface";
import JudgeIdentityGateway from "../../gateways/judgeIdentityGateway";

export default class IdentityHandler {

  constructor(private source: JudgeIdentityGateway) {}

  async register(judge: Judge): Promise<void> {
    await this.source.register(judge);
  }
    
  currentJudgeStatus(): JudgeIdentity {
    return this.source.currentJudgeStatus();
  }
  
  current(): Judge {
    return this.source.current();
  }

  retrieveSession(): { current: Judge, identified: boolean } | null {
    return this.source.retrieveSession();
  }

  updateSession(): Promise<void> {
    return this.source.updateSession();
  }

  subscribeToEntity(judgeId: number, entityId: number): Promise<void> {
    return this.source.subscribeToEntity(judgeId, entityId);
  }
}
import { Company } from "../../../../corelogic/entities/company.interface";
import { Criterion } from "../../../../corelogic/entities/criterion.interface";
import { Thematic } from "../../../../corelogic/entities/thematic.interface";
import ThematicGateway from "../../../../corelogic/gateways/thematicGateway";
import criteriasFixture from "../../../../fixtures/criteria/criteriaFixture";
import companiesFixtures from "../../../../fixtures/thematics/companiesFixtures";

export default class InMemoryThematicGateway implements ThematicGateway {
    
    private source: Thematic[];

    constructor(source: Thematic[]) {
      this.source = source;
    }

    async all(): Promise<Thematic[]> {
      return this.source; 
    }

    async allCompaniesByThematic(thematicId: number): Promise<Company[]> {
      return companiesFixtures[thematicId];
    } 

    async allCompanies(): Promise<Company[]> {
      return companiesFixtures[0];
    }

    async allCriteria(): Promise<Criterion[]> {
      return criteriasFixture();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async allCriteriaByThematic(themeId: number): Promise<Criterion[]> {
      return criteriasFixture();
    }
  
    
}
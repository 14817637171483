import { Box, Image } from 'grommet';
import React, { FunctionComponent, useEffect, useState } from 'react';
import PexeEventHandler from '../../../../corelogic/usecases/event/eventHandler';

const PexeFooter: FunctionComponent<{ eventHandler: PexeEventHandler }> = ({ eventHandler }): JSX.Element => {

  const [footer, setFooter] = useState<string>();

  useEffect(() => {
    let mounted = true;

    const fetchFooter = async () => {
      const fetchedFooter = await eventHandler.getEventFooter();
      if(fetchedFooter) {
        setFooter(fetchedFooter);
      }
    }
    if(mounted) {
      fetchFooter();
    }

    return () => {
      mounted = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box 
    as='footer'
    direction='row'
    align='center'
    justify='center'
    responsive 
  >
    { footer ?
      <Image 
        fit="cover"
        src={footer}  
        style={{maxHeight: "500px", overflow: "hidden", width: "100%", height: "auto"}}
      /> 
      :  <div style={{height: "500px"}} />
    }
    
  </Box>
};

export default PexeFooter;

import axios from "axios";
import { Judge, JudgeIdentity } from "../../../../corelogic/entities/judge.interface";
import JudgeIdentityGateway from "../../../../corelogic/gateways/judgeIdentityGateway";

export default class HttpJudgeIdentityGateway implements JudgeIdentityGateway {

    endpoint = process.env.REACT_APP_API_URL;

    currentJudge: JudgeIdentity = this.initSession();

    async register(judge: Judge): Promise<void> {
      try {

        if(!this.currentJudgeStatus().identified) {
          const { data } = await axios.post<Judge>(`${this.endpoint}/judges`, { entityName: judge.entityName, email: judge.email }); 

          const { id } = data;
  
          judge.id = id;
        }
        
        this.currentJudge = {
          current: judge,
          identified: true
        };
  
        const session = {
          profile: judge,
          expireAt: new Date().getTime() + 36e+6
        };
  
        localStorage.setItem("pexe-session", JSON.stringify(session));

      // eslint-disable-next-line no-empty
      } catch {}
    }
  
    currentJudgeStatus(): JudgeIdentity {
      return this.currentJudge;
    }

    current(): Judge {
      return this.currentJudge.current;
    } 

    retrieveSession(): { current: Judge, identified: boolean } | null {
      const session = localStorage.getItem("pexe-session");

      if(session) {
        const { profile, expireAt } = JSON.parse(session);

        if(expireAt > new Date().getTime()) {
          this.currentJudge = { current: profile, identified: true };
          return this.currentJudge;
        } 

        localStorage.removeItem("pexe-session");

        return null;
      }
      
      return null;
    }

    async updateSession(): Promise<void> {
      await this.register(this.current());
    }

    async subscribeToEntity(judgeId: number, entityId: number): Promise<void> {
      await axios.put(`${this.endpoint}/judges/${judgeId}/${entityId}`);
    }

    private initSession() {
      return {
        current: {
          id: 0,
          entityName: "",
          email: "",
          rates: {},
        },
        identified: false
      };
    }

    
}
import { Criterion } from "../../corelogic/entities/criterion.interface";

const criteriasFixture = (): Criterion[] => ([
  {
    id: 42,
    criteriaFr: "Innovation",
    criteriaEn: "Innovation",
    weight: 21
  },
  {
    id: 12,
    criteriaFr: "Budget",
    criteriaEn: "Budget",
    weight: 12
  },
  {
    id: 122,
    criteriaFr: "Ecolo",
    criteriaEn: "Green",
    weight: 50
  }
]);

export default criteriasFixture;